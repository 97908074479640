import { api } from '../api'; // Đảm bảo rằng bạn đã định nghĩa và xuất đối tượng api đúng cách
import { message } from 'antd'; // Giả sử bạn sử dụng Ant Design cho các thông báo
import isCorrectNumberPhone from './isCorrectNumberPhone'
const connectCSKH = async (support) => {
    /*
  console.log("connectCSKH called with support:", support); // Thêm log để kiểm tra giá trị của support
  try {
    // Nếu support là 0, mở link Facebook
    if (support === 0 || support === '0') {
      window.open('https://web.facebook.com/CSKHVo.Thanh.Dien.183MBBANK/', '_blank');
      return;
    }
    window.open(`https://zalo.me/${support}`, '_blank');
  } catch (err) {
    console.log("Error occurred:", err);
    message.error('Xảy ra lỗi, vui lòng thử lại sau');
  }
  */
};

export default connectCSKH;
